'use client';
import Link from 'next/link';
import Routes from '@/constants/Routes';
import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import { ReloadIcon } from '@radix-ui/react-icons';

const Error = ({ title, subTitle }) => {
    return (
        <section className='flex h-screen w-screen flex-1 bg-gray-50 p-16 dark:bg-gray-700'>
            <div className='container flex flex-col items-center'>
                <div className='flex max-w-md flex-col gap-8 text-center'>
                    {/* Title */}
                    <h2 className='text-6xl font-extrabold text-gray-600 dark:text-gray-100'>
                        <span className='sr-only'>Error</span>
                        {title}
                    </h2>
                    {/* Subtitle */}
                    <p className='text-xl text-gray-500 dark:text-gray-300'>{subTitle}</p>
                    <div className='flex w-full items-center justify-center gap-x-4'>
                        <Link href={Routes.DASHBOARD}>
                            <Button variant='outline' size='lg'>
                                <ArrowLeft className='mr-1 h-5 w-5' /> Back to Home
                            </Button>
                        </Link>
                        <Button
                            onClick={() => window.location.reload()}
                            variant='secondary'
                            size='lg'
                        >
                            <ReloadIcon className='mr-1 h-4 w-4' /> Reload
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Error;
