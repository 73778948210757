import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/utils';
import { LoaderCircle } from 'lucide-react';
import Tooltip from '../Tooltip/Tooltip';
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';

const buttonVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default: 'bg-primary text-primary-foreground shadow hover:bg-primary/90',
                success: 'bg-green-600 text-primary-foreground shadow hover:bg-green-600/90',
                blue: 'bg-blue-600 text-primary-foreground shadow hover:bg-blue-600/90',
                destructive:
                    'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
                outline:
                    'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
                secondary: 'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
                ghost: 'hover:bg-accent hover:text-accent-foreground',
                link: 'text-primary underline-offset-4 hover:underline',
            },
            size: {
                default: 'h-9 px-4 py-2',
                sm: 'h-8 rounded-md px-3 text-xs',
                lg: 'h-10 rounded-md px-8',
                icon: 'h-9 w-9',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
);

export interface ButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
    isLoading?: boolean;
    tooltip?: ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            variant,
            size,
            asChild = false,
            isLoading,
            children,
            type,
            disabled,
            tooltip,
            ...props
        },
        ref
    ) => {
        const Comp = asChild ? Slot : 'button';
        const El = (
            <Comp
                type={type}
                className={cn(
                    isLoading && 'flex-center',
                    buttonVariants({ variant, size, className })
                )}
                ref={ref}
                disabled={disabled || isLoading}
                aria-label={tooltip && typeof tooltip === 'string' ? tooltip : ''}
                {...props}
            >
                {isLoading ? (
                    <LoaderCircle
                        className='animate-spin'
                        size={15}
                        color={variant === 'default' || variant === 'ghost' ? 'black' : 'white'}
                    />
                ) : (
                    children
                )}
            </Comp>
        );
        if (tooltip) {
            return <Tooltip content={tooltip}>{El}</Tooltip>;
        }
        return El;
    }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
